
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import AuthService, { Auth } from '@/services/auth.service';
import lazyInject from '@/di';
import { AuthType } from '@/common/enum';
import { FormModel } from 'ant-design-vue';
import StorageService from '@/services/storage.service';

@Component({})
export default class Login extends Vue {
  @Ref() readonly ruleForm!: FormModel;

  AuthType = AuthType;

  visible = true;

  loading = false;

  formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 },
  };

  imgData: {
    img: string;
    key: string;
    sensitive: boolean;
  } = {
    img: '',
    key: '',
    sensitive: false,
  };

  form: {
    phone: string;
    password: string;
    memory: boolean;
    code: string;
    captcha: string;
  } = {
    phone: '',
    password: '',
    memory: true,
    code: '',
    captcha: '',
  };

  rules = {
    phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
    captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  };

  memory = false;

  auth: Auth = new Auth();

  /** 发送验证码倒计时 */
  countDown = 0;

  get codeBtnTxt(): string {
    if (this.countDown === 0) {
      return '发送验证码';
    } else {
      return `发送验证码(${this.countDown})`;
    }
  }

  @lazyInject(AuthService)
  authService!: AuthService;

  @lazyInject(StorageService)
  protected storage!: StorageService;

  @Watch('form', { deep: true })
  onFormChange() {
    const { phone, password, code, captcha } = this.form;
    this.auth.phone = phone;
    this.auth.password = password;
    this.auth.code = code;
    this.auth.captcha = captcha;
    this.auth.captchaKey = this.imgData.key;
  }
  getImg() {
    if (this.form.phone.length === 11) {
      this.changeImg();
    }
  }
  async changeImg() {
    try {
      const result = await this.authService.captcha(this.form.phone);
      this.imgData = result;
    } catch (error) {
          // this.$message.error(`${error}`);
    }
  }
  /** 提交登陆 */
  check() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        if (!this.checkAndNoticeError()) {
          return;
        }
        try {
          this.loading = true;
          const result = await this.authService.login(this.auth, this.$el);
          if (!result) return false;
          this.storage.set('phone', this.auth.phone);
          this.$message.success('登录成功');
          // 跳转首页
          const { redirect } = this.$route.query || {};
          if (redirect) {
            this.$router.push(redirect as string);
          } else {
            this.$router.push('/');
          }
        } catch (error) {
          // this.$message.error(`${error}`);
          this.form.captcha = '';
          this.changeImg();
          this.loading = false;
        }
      }
    });
  }

  /** 检测输入合法性 */
  checkAndNoticeError() {
    if (this.auth.isValid()) {
      return true;
    }

    if (!this.auth.isPhoneValid()) {
      this.$message.warn('手机格式不正确，请重新输入');
      return false;
    }

    if (this.auth.type === AuthType.PASSWORD && !this.auth.isPasswordValid()) {
      this.$message.warn('请输入密码');
      return false;
    }
    if (
      this.auth.type === AuthType.VERIFICATION_CODE &&
      !this.auth.isVerificationCodeValid()
    ) {
      this.$message.warn('验证码格式不正确');
      return false;
    }
    return true;
  }

  /** 切换登录方式 */
  changeLoginType() {
    if (this.auth.type === AuthType.PASSWORD) {
      this.auth.type = AuthType.VERIFICATION_CODE;
      this.auth.code = '';
    } else {
      this.auth.type = AuthType.PASSWORD;
      this.auth.password = '';
    }
    this.auth.captcha = '';
    this.ruleForm.clearValidate(['password', 'code', 'captcha']);
  }

  /** 手机号检测 */
  testPhoneValid(): boolean {
    return /1[3-9]\d{9}/.test(this.form.phone);
  }

  /** 获取短信验证码 */
  async getCode(): Promise<void> {
    this.loading = true;

    if (!this.form.phone) {
      this.$message.warn('请输入手机号码');
      this.loading = false;
      return;
    }
    if (!this.testPhoneValid()) {
      this.$message.warn('手机格式不正确，请重新输入');
      this.loading = false;
      return;
    }

    try {
      const result = await this.authService.sendAuth(this.auth);
      if (result === 'OK') {
        this.$message.success('验证码已发送');
        this.countDown = 60;
        const countDownTimer = setInterval(() => {
          this.countDown -= 1;
          if (this.countDown === 0) {
            clearInterval(countDownTimer);
          }
        }, 1000);
      } else {
        this.$message.error('未知错误，请重试');
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }
}
